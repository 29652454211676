.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: url("./../public/assets/fonts/Montserrat-Regular.ttf"); /* IE9 Compat Modes */
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  src: url("./../public/assets/fonts/Montserrat-Bold.ttf"); /* IE9 Compat Modes */
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  src: url("./../public/assets/fonts/Montserrat-Medium.ttf"); /* IE9 Compat Modes */
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  src: url("./../public/assets/fonts/Montserrat-SemiBold.ttf"); /* IE9 Compat Modes */
}

.spaced-h-30 > *:not(:first-child) {
  margin-left: 30px;
}
.spaced-h-12-flx1 > * {
  flex: 1;
}
.spaced-h-12-flx1 > *:not(:first-child) {
  margin-left: 12px;
}
.square-corners {
  border-radius: 0 !important;
}

.dialog-content-container > div {
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.blur {
  filter: blur(5px);
}

.errorBar {
  animation-duration: 3s;
  animation-name: pulse;
  opacity: 1;
  animation-iteration-count: infinite;
}

@keyframes pulse {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}
