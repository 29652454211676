body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.botendienstButton {
    position: absolute;
    left: calc((1185 / 1440) * 100vw);
    top: calc((640 / 774) * 100vh);
    width: calc((243 / 1440) * 100vw);
    height: calc((63 / 774) * 100vh);
    /*background-color: #49BEEF;*/
    background-color: #77BCE7;
    border: none;
    border-radius: calc((6 / 1440) * 100vw);
    box-shadow: 0px 7px 20px 0px rgba(138, 138, 138, 0.77);
    cursor: pointer;
}

.botendienstAuto {
    position: absolute;
    left: calc((12 / 1440) * 100vw);
    top: calc((15 / 774) * 100vh);
    height: calc((32 / 774) * 100vh);
}

.botendienstText {
    position: absolute;
    left: calc((56 / 1440) * 100vw);
    top: calc((-8 / 774) * 100vh);
    font-size: calc((24 / 774) * 100vh);
    font-family: "Montserrat";
    font-weight: bold;
    letter-spacing: calc((.43 / 1440) * 100vw);
    color: white;
}
